<template>
  <v-card height="max-content" class="pb-5">
    <v-btn icon class="close-icon" @click="$emit('cancel')"
      ><v-icon>close</v-icon></v-btn
    >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text style="height: calc(100% - 150px)">
      <v-container v-if="isVideo" class="fill-height">
        <v-row no-gutters justify="center" style="height: 100%">
          <video ref="video-control" style="height: 600px; width: 100%" controls>
            Your browser does not support the video tag.
          </video>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row justify="center">
          <v-img
            v-if="isImg"
            contain
            :src="previewUrl"
            width="100%"
            height="600"
          >
          </v-img>
          <iframe
            v-else
            :src="previewUrl"
            frameborder="0"
            width="100%"
            height="600"
          ></iframe>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="showDownloadButton">
      <v-btn @click="download">Download</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { MediaPlayer } from "dashjs";

let player = undefined;
function attachQs(qs) {
  return () => ({
    modifyRequestHeader: function(xhr) {
      /* Add custom header. Requires to set up Access-Control-Allow-Headers in your */
      /* response header in the server side. Reference: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader */
      /* xhr.setRequestHeader('DASH-CUSTOM-HEADER', 'MyValue'); */
      return xhr;
    },
    modifyRequestURL: function(url) {
      /* Modify url adding a custom query string parameter */
      return !url.includes("?") ? url + "?" + qs : url;
    }
  });
}
export default {
  name: "FilePreviewer",
  props: {
    title: {
      type: String,
      default: ""
    },
    previewUrl: {
      type: String,
      default: undefined,
      required: true
    },
    showDownloadButton: {
      type: Boolean,
      default: true
    },
    downloadUrl: {
      type: String,
      default: undefined,
      required: true
    },
    isVideo: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    fileName() {
      const parts = this.previewUrl.split("/");
      return parts[parts.length - 2];
    },
    isImg() {
      let ext = this.previewUrl
        .split("?")[0]
        .split("/")
        .pop()
        .split(".")[1];
      return ["jpg", "jpeg", "png", "bmp", "gif"].includes(ext);
    }
  },
  watch: {
    fileName() {
      if (this.fileName && this.isVideo === true) {
        this.playVideo();
      }
    }
  },
  created() {},
  beforeMount() {
    player = MediaPlayer().create();
  },
  mounted() {
    if (this.fileName && this.isVideo === true) {
      this.playVideo();
    }
  },
  beforeDestroy() {
    player = undefined;
  },
  methods: {
    download() {
      window.open(this.downloadUrl);
    },
    playVideo() {
      const pieces = this.previewUrl.split("?");
      if (pieces.length > 1) {
        player.extend("RequestModifier", attachQs(pieces[1]));
      }
      player.initialize(this.$refs["video-control"], this.previewUrl, true);
    }
  }
};
</script>

<style></style>
