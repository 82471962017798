<template>
  <div>
    <v-card
      :class="!renderInCourseDesign ? 'lecture-card' : 'pa-3 rounded-xl border-1-2 border-solid border-primary'"
      class="mx-auto"
      :max-width="550"
    >
      <div class="pa-0 ma-0 d-flex">
        <!-- <div class="lecture-image-section pa-0 ma-0">
          <img src="../assets/Lecture.png" alt="" />
        </div> 
        -->
        <div :class="!renderInCourseDesign ? 'lecture-other-section pa-0 ma-0' : ''">
          <v-row class="pa-0 ma-0">
            <v-col
              cols="9"
              class="ma-0 pr-0 pb-0 lecture-title-dropdown-section"
            >
              <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0">
                  <h1
                    class="pa-0 ma-0 d-inline-block text-truncate lecture-title"
                  >
                    {{ lecture.title }}
                  </h1>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                  <h2 class="pa-0 ma-0 font-weight-regular lecture-upload-date">
                    {{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }} :
                    {{
                      !$language
                        ? `${lecture.upload_date}`
                        : new Date(lecture.upload_date).toLocaleString(
                            "bn-BD",
                            { dateStyle: "medium" }
                          )
                    }}
                  </h2>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="!renderInCourseDesign" cols="3" class="ma-0 pa-0">
              <div class="text-right lecture-card-menu">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="isOwner"
                      color="black"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      class="lecture-card-menu-list-item clickable"
                      @click="shouldPopupDelateDialog = true"
                    >
                      <v-list-item-title class="subtitle-2 custom-font-family">
                        <v-icon>fas fa-list</v-icon
                        >{{
                          !$language ? "Delete Lecture" : "লেকচার ডিলিট করুন"
                        }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="!renderInCourseDesign" class="pa-0 ma-0">
            <v-col class="pr-0 pb-0 ma-0 lecture-share-with-section" cols="12">
              <v-card-subtitle
                v-if="!$language"
                class="custom-font-family font-weight-medium pa-0 ma-0"
              >
                <img
                  src="../assets/Share_N_Batch.svg"
                  alt=""
                  height="31"
                  width="31"
                  class="mr-2"
                />
                With {{ share_batches_count }} Batches
              </v-card-subtitle>
              <v-card-subtitle
                v-else
                class="custom-font-family font-weight-medium pa-0 ma-0"
              >
                <img
                  src="../assets/Share_N_Batch.svg"
                  alt=""
                  height="31"
                  width="31"
                  class="mr-2"
                />
                {{ share_batches_count }} টি ব্যাচের সাথে শেয়ার করা হয়েছে
              </v-card-subtitle>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col
              v-if="!renderInCourseDesign"
              cols="5"
              class="pr-0 pb-0 ma-0 mk-lecture-detail"
            >
              <v-tooltip top :disabled="lectureAvailable">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    plain
                    class="custom-font-family"
                    :class="{ 'grey--text': lecture.is_available === false }"
                    :readonly="!lectureAvailable"
                    @click="download"
                  >
                    <v-progress-circular
                      v-if="!lectureAvailable"
                      indeterminate
                    ></v-progress-circular>
                    <span v-else>{{
                      !$language ? "Details" : "বিস্তারিত"
                    }}</span>
                  </v-btn>
                </template>
                <span>{{ unavailabilityMessage }}</span>
              </v-tooltip>
            </v-col>
            <v-col v-else class="pt-6">
              <v-btn color="#0099dc" height="50" width="140" @click="download">
                <v-row
                  no-gutters
                  style="height: 100%"
                  justify="center"
                  align="center"
                >
                  <v-icon v-if="lecture.is_video" color="#fff">mdi-play</v-icon>

                  <v-icon v-else color="#fff" size="30">
                    mdi-file-search
                  </v-icon>

                  <p class="white--text">
                    {{ lecture.is_video ? "Play" : "Preview" }}
                  </p>
                </v-row>
              </v-btn>
            </v-col>
            <v-col
              v-if="!renderInCourseDesign"
              cols="7"
              class="px-0 pb-0 ma-0 mk-lecture-share"
            >
              <v-btn
                depressed
                tile
                plain
                class="custom-font-family"
                @click="$emit('share')"
              >
                <img
                  src="../assets/Share_Btn.svg"
                  width="25"
                  height="25"
                  alt=""
                  class="mr-2"
                />
                {{ !$language ? "Share" : "শেয়ার করুন" }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="isFilePreviewerVisible" width="100vw">
      <file-previewer
        v-if="isFilePreviewerVisible"
        :preview-url="previewUrl"
        :download-url="downloadUrl"
        :title="lecture.title"
        :is-video="lecture.is_video ? lecture.is_video : false"
        @cancel="isFilePreviewerVisible = false"
      ></file-previewer>
    </v-dialog>
    <div v-if="isOwner">
      <dialog-delete
        :item-text="!$language ? 'Lecture' : 'লেকচার'"
        :value="shouldPopupDelateDialog"
        :deleting="deleting"
        @confirm-remove="removeLecture"
        @cancel="shouldPopupDelateDialog = false"
      >
      </dialog-delete>
    </div>
  </div>
</template>

<script>
import DialogDelete from "@ecf/global/components/DialogDelete";
import lectureService from "#ecf/lecture/services/LectureService";
import FilePreviewer from "~ecf/components/FilePreviewer";
export default {
  name: "MLectureListItem",
  components: {
    FilePreviewer,
    DialogDelete,
  },
  props: {
    lecture: {
      type: Object,
      required: true,
    },
    renderInCourseDesign: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      previewUrl: undefined,
      downloadUrl: undefined,
      isFilePreviewerVisible: false,
      shouldPopupDelateDialog: false,
      deleting: false,
      downloading: false,
    };
  },

  computed: {
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
    share_batches_count() {
      if (this.renderInCourseDesign) {
        return 0;
      } else {
        return this.lecture.shared_batches.length;
      }
    },
    lectureAvailable() {
      return this.lecture.is_available;
    },
    unavailabilityMessage() {
      return this.$language
        ? "আপনার আপলোড প্রক্রিয়াধীন আছে দয়া করে অপেক্ষা করুন"
        : "Please wait until your upload has finished being processed";
    },
  },

  created() {},
  methods: {
    async removeLecture() {
      if (this.deleting) return;
      try {
        this.deleting = true;
        await this.$store.dispatch("lecture/remove", {
          lectureId: this.lecture.object_id,
          courseId: this.lecture.course_id,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
        this.shouldPopupDelateDialog = false;
      }
    },

    async download() {
      if (this.lecture.is_available === false) return;
      if (this.downloading) return;
      try {
        this.downloading = true;
        this.isFilePreviewerVisible = true;
        this.previewUrl = "loading";
        this.downloadUrl = "loading";
        let lecture = await lectureService.get(
          this.lecture.object_id,
          this.lecture.course_id
        );
        this.previewUrl = lecture.preview_url;
        this.downloadUrl = lecture.download_url;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "#ecf/lecture/styles/MLectureListItem.scss";
</style>
