var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pb-5", attrs: { height: "max-content" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-icon",
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [_c("v-icon", [_vm._v("close")])],
        1
      ),
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-card-text",
        { staticStyle: { height: "calc(100% - 150px)" } },
        [
          _vm.isVideo
            ? _c(
                "v-container",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c(
                        "video",
                        {
                          ref: "video-control",
                          staticStyle: { height: "600px", width: "100%" },
                          attrs: { controls: "" },
                        },
                        [
                          _vm._v(
                            " Your browser does not support the video tag. "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _vm.isImg
                        ? _c("v-img", {
                            attrs: {
                              contain: "",
                              src: _vm.previewUrl,
                              width: "100%",
                              height: "600",
                            },
                          })
                        : _c("iframe", {
                            attrs: {
                              src: _vm.previewUrl,
                              frameborder: "0",
                              width: "100%",
                              height: "600",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm.showDownloadButton
        ? _c(
            "v-card-actions",
            [
              _c("v-btn", { on: { click: _vm.download } }, [
                _vm._v("Download"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }