var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          class: !_vm.renderInCourseDesign
            ? "lecture-card"
            : "pa-3 rounded-xl border-1-2 border-solid border-primary",
          attrs: { "max-width": 550 },
        },
        [
          _c("div", { staticClass: "pa-0 ma-0 d-flex" }, [
            _c(
              "div",
              {
                class: !_vm.renderInCourseDesign
                  ? "lecture-other-section pa-0 ma-0"
                  : "",
              },
              [
                _c(
                  "v-row",
                  { staticClass: "pa-0 ma-0" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "ma-0 pr-0 pb-0 lecture-title-dropdown-section",
                        attrs: { cols: "9" },
                      },
                      [
                        _c(
                          "v-row",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 d-inline-block text-truncate lecture-title",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.lecture.title) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "pa-0 ma-0 font-weight-regular lecture-upload-date",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          !_vm.$language
                                            ? "Upload Date"
                                            : "প্রকাশের তারিখ"
                                        ) +
                                        " : " +
                                        _vm._s(
                                          !_vm.$language
                                            ? `${_vm.lecture.upload_date}`
                                            : new Date(
                                                _vm.lecture.upload_date
                                              ).toLocaleString("bn-BD", {
                                                dateStyle: "medium",
                                              })
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.renderInCourseDesign
                      ? _c(
                          "v-col",
                          { staticClass: "ma-0 pa-0", attrs: { cols: "3" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-right lecture-card-menu" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "", left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _vm.isOwner
                                                ? _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "black",
                                                            icon: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      152086974
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "lecture-card-menu-list-item clickable",
                                            on: {
                                              click: function ($event) {
                                                _vm.shouldPopupDelateDialog = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass:
                                                  "subtitle-2 custom-font-family",
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fas fa-list"),
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.$language
                                                      ? "Delete Lecture"
                                                      : "লেকচার ডিলিট করুন"
                                                  )
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.renderInCourseDesign
                  ? _c(
                      "v-row",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pr-0 pb-0 ma-0 lecture-share-with-section",
                            attrs: { cols: "12" },
                          },
                          [
                            !_vm.$language
                              ? _c(
                                  "v-card-subtitle",
                                  {
                                    staticClass:
                                      "custom-font-family font-weight-medium pa-0 ma-0",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: require("../assets/Share_N_Batch.svg"),
                                        alt: "",
                                        height: "31",
                                        width: "31",
                                      },
                                    }),
                                    _vm._v(
                                      " With " +
                                        _vm._s(_vm.share_batches_count) +
                                        " Batches "
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-card-subtitle",
                                  {
                                    staticClass:
                                      "custom-font-family font-weight-medium pa-0 ma-0",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: require("../assets/Share_N_Batch.svg"),
                                        alt: "",
                                        height: "31",
                                        width: "31",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.share_batches_count) +
                                        " টি ব্যাচের সাথে শেয়ার করা হয়েছে "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "pa-0 ma-0" },
                  [
                    !_vm.renderInCourseDesign
                      ? _c(
                          "v-col",
                          {
                            staticClass: "pr-0 pb-0 ma-0 mk-lecture-detail",
                            attrs: { cols: "5" },
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  disabled: _vm.lectureAvailable,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "custom-font-family",
                                                  class: {
                                                    "grey--text":
                                                      _vm.lecture
                                                        .is_available === false,
                                                  },
                                                  attrs: {
                                                    depressed: "",
                                                    tile: "",
                                                    plain: "",
                                                    readonly:
                                                      !_vm.lectureAvailable,
                                                  },
                                                  on: { click: _vm.download },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              !_vm.lectureAvailable
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                    },
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        !_vm.$language
                                                          ? "Details"
                                                          : "বিস্তারিত"
                                                      )
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3029256581
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.unavailabilityMessage)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "v-col",
                          { staticClass: "pt-6" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "#0099dc",
                                  height: "50",
                                  width: "140",
                                },
                                on: { click: _vm.download },
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      "no-gutters": "",
                                      justify: "center",
                                      align: "center",
                                    },
                                  },
                                  [
                                    _vm.lecture.is_video
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "#fff" } },
                                          [_vm._v("mdi-play")]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "#fff",
                                              size: "30",
                                            },
                                          },
                                          [_vm._v(" mdi-file-search ")]
                                        ),
                                    _c("p", { staticClass: "white--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.lecture.is_video
                                              ? "Play"
                                              : "Preview"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    !_vm.renderInCourseDesign
                      ? _c(
                          "v-col",
                          {
                            staticClass: "px-0 pb-0 ma-0 mk-lecture-share",
                            attrs: { cols: "7" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-font-family",
                                attrs: { depressed: "", tile: "", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("share")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    src: require("../assets/Share_Btn.svg"),
                                    width: "25",
                                    height: "25",
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language ? "Share" : "শেয়ার করুন"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "100vw" },
          model: {
            value: _vm.isFilePreviewerVisible,
            callback: function ($$v) {
              _vm.isFilePreviewerVisible = $$v
            },
            expression: "isFilePreviewerVisible",
          },
        },
        [
          _vm.isFilePreviewerVisible
            ? _c("file-previewer", {
                attrs: {
                  "preview-url": _vm.previewUrl,
                  "download-url": _vm.downloadUrl,
                  title: _vm.lecture.title,
                  "is-video": _vm.lecture.is_video
                    ? _vm.lecture.is_video
                    : false,
                },
                on: {
                  cancel: function ($event) {
                    _vm.isFilePreviewerVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isOwner
        ? _c(
            "div",
            [
              _c("dialog-delete", {
                attrs: {
                  "item-text": !_vm.$language ? "Lecture" : "লেকচার",
                  value: _vm.shouldPopupDelateDialog,
                  deleting: _vm.deleting,
                },
                on: {
                  "confirm-remove": _vm.removeLecture,
                  cancel: function ($event) {
                    _vm.shouldPopupDelateDialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }